import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GeneralConfig } from '@core/models/header-config.model';

@Injectable({
  providedIn: 'root',
})
export class GeneralConfigService {
  private _generalConfig: BehaviorSubject<GeneralConfig> = new BehaviorSubject<GeneralConfig>({
    mainHeader: '',
    backUrl: '',
    showNavigationBar: false,
  });

  public setGeneralConfig(generalConfig: GeneralConfig): void {
    this._generalConfig.next(generalConfig);
  }

  public patchGeneralConfig(generalConfig: Partial<GeneralConfig>): void {
    this._generalConfig.next({
      ...this._generalConfig.getValue(),
      ...generalConfig,
    });
  }

  public getGeneralConfig(): Observable<GeneralConfig> {
    return this._generalConfig.asObservable();
  }

  public getGeneralConfigSnapshot(): GeneralConfig {
    return this._generalConfig.getValue();
  }
}
